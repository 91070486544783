<template>
  <div class="table" @scroll.passive="getScroll($event)">
    <el-tabs type="card" class="tb-tabs tabs-bg" v-model="activeName">
      <el-tab-pane disabled>
        <span slot="label"><h3>竞赛详情</h3></span>
      </el-tab-pane>
      <el-tab-pane
        v-for="item in tabMapOptions"
        :key="item.key"
        :label="item.label"
        :name="item.key"
      >
        <!-- <keep-alive> -->
        <contest-detail
          v-if="activeName == item.key && canShow"
          :type="item.key"
          :contestInfo="editData"
          :scrollTopTable="scrollTopTable"
        ></contest-detail>
        <!-- </keep-alive> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ContestDetail from "@/components/research/detail-pane";
import {
  getContextInfo,
  getInfoByContestAndUser,
} from "@/api/research/contest";
export default {
  watch: {
    $route() {
      this.canShow = false;
    },
  },
  components: { ContestDetail },
  provide() {
    return {
      getContextInfoDetail: this.getContextInfo,
    };
  },
  inject: ["reload"],
  data() {
    return {
      canShow: false,
      editData: {},
      activeName: "Info",
      tabMapOptions: [
        { label: "竞赛信息", key: "Info" },
        { label: "竞赛介绍", key: "Introduce" },
        { label: "竞赛学员", key: "Student" },
        { label: "竞赛题目", key: "Problem" },
        { label: "提交记录", key: "SubmiteRecord" },
        { label: "竞赛排名", key: "Sort" },
        { label: "管理员", key: "Administrater" },
      ],
      scrollTopTable: 0,
    };
  },
  mounted() {
    this.getContextInfo();
  },
  methods: {
    // 查看用户在竞赛中的角色
    async getInfoByContestAndUser() {
      const response = await getInfoByContestAndUser({
        contestId: this.$route.query.contestId,
      });
      if (response && response.state === "success") {
        this.editData.role = response.body.role;
        this.canShow = true;
      }
    },
    // 查看竞赛详情
    async getContextInfo() {
      const response = await getContextInfo({
        contest_id: this.$route.query.contestId,
        defunct: "",
      });
      if (response && response.state === "success") {
        this.editData = response.body;
        this.tabMapOptions = [
          { label: "竞赛信息", key: "Info" },
          { label: "竞赛介绍", key: "Introduce" },
          { label: "竞赛学员", key: "Student" },
          { label: "竞赛题目", key: "Problem" },
          { label: "提交记录", key: "SubmiteRecord" },
          { label: "竞赛排名", key: "Sort" },
        ];
        if (this.editData.lotteryOpen === "Y") {
          this.tabMapOptions.push({ label: "竞赛抽奖", key: "lottery" });
        }
        this.tabMapOptions.push({ label: "管理员", key: "Administrater" });
        this.getInfoByContestAndUser();
      }
    },
    back() {
      this.$router.go(-1);
    },

    getScroll(event) {
      // console.log(event.target.scrollTop)
      this.scrollTopTable = event.target.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/table";
.tabs-bg {
  background-color: #ffffff;
}
/deep/ .el-tabs__item.is-disabled {
  color: #222222 !important;
}
</style>
